/* Ensure the calendar stays below the header */
.dashboard-container {
    display: flex;
    flex-direction: column;
    height: 100vh;
  }
  
  /* Header styling (adjust this to match your existing header styles) */
  .dashboard-header {
    height: 60px; /* Adjust to match the height of your header */
    background-color: #3666b3; /* Example color */
    color: white;
    display: flex;
    align-items: center;
    padding: 0 20px;
    font-size: 1.5rem;
    font-weight: bold;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  /* Main content area below the header */
  .dashboard-main {
    display: flex;
    flex: 1; /* Take the remaining vertical space */
    overflow: hidden; /* Prevents scrollbars if content overflows */
  }
  
  /* Calendar styling */
.dashboard-calendar {
  width: 66.66%; /* Take 2/3 of the screen width */
  padding: 20px;
  box-sizing: border-box;
  border-right: 1px solid #ddd; /* Optional: divider between sections */
  background-color: #ffffff; /* White background for the calendar */
}
  
  /* Optional right-side content styling (if needed) */
  .dashboard-sidebar {
    display: flex; /* Flexbox for vertical alignment */
    flex-direction: column; /* Arrange children vertically */
    justify-content: space-between; /* Push content to top and bottom */
    width: 33.33%; /* Sidebar width */
    padding: 20px;
    box-sizing: border-box;
    background-color: #500000 !important; /* Maroon background */
    color: #ffffff; /* White text for better contrast */
    overflow-y: auto; /* Scrollable if content overflows */
  }

.dashboard-sidebar p {
  color: #ffffff; /* White text */
  margin-bottom: 10px;
  font-size: 1rem;
  line-height: 1.5;
}
  
  /* Calendar container for proper alignment */
  .calendar-container {
    height: calc(100% - 40px); /* Adjust for padding or margins */
    background-color: white; /* Background for the calendar area */
    border: 1px solid #ccc;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }

  /* Sidebar Buttons */
  .sidebar-button {
    display: block;
    width: 100%;
    margin: 10px 0;
    padding: 10px;
    background-color: #ffffff !important; /* White */
    color: #000000 !important; /* Black text */
    text-align: center;
    /*border: 2px solid #000000 !important; /* Black border */
    border-radius: 8px;
    cursor: pointer;
    font-size: 1rem;
    font-weight: bold;
    transition: background-color 0.3s ease, transform 0.2s ease !important;
  }
  
  .sidebar-button:hover {
    background-color: #a52a2a; /* Lighter maroon on hover */
    transform: scale(1.02); /* Slight zoom effect on hover */
  }

  /* Sidebar Buttons Container */
  .sidebar-buttons {
    display: flex; /* Flexbox for side-by-side alignment */
    justify-content: space-between; /* Space between the buttons */
    align-items: center; /* Vertically center the buttons */
    margin-top: auto; /* Push to the bottom of the sidebar */
    gap: 10px; /* Add spacing between buttons */
  }

  /* Individual Buttons */
  .cp-button {
    flex: 1; /* Make the buttons take up equal space */
    max-width: 48%; /* Prevent buttons from being too wide */
    padding: 10px;
    background-color: #ffffff !important; /* White background */
    color: #000000 !important; /* Black text */
    text-align: center;
    border-radius: 8px;
    cursor: pointer;
    font-size: 1rem;
    font-weight: bold;
    transition: background-color 0.3s ease, transform 0.2s ease;
  }
  
  .cp-button:hover {
    background-color: #a52a2a; /* Lighter maroon on hover */
    transform: scale(1.02); /* Slight zoom effect on hover */
  }
  
/* Modal Overlay - Center the Modal */
.modal-overlay {
  position: fixed;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.6); /* Semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1200;
  overflow-y: auto; /* Allow scrolling if content is too large */
}

/* Modal Content */
.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  width: 90%; /* Take up 90% of the screen width */
  max-width: 600px; /* Limit maximum width */
  max-height: 90vh; /* Prevent overflow of the screen */
  overflow-y: auto; /* Scroll inside modal if content exceeds height */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  animation: fadeIn 0.3s ease-in-out;
}

/* Ensure proper spacing in modal */
.modal-content form label {
  margin-bottom: 8px;
  font-weight: bold;
  color: #333;
}

.modal-content form input,
.modal-content form select {
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 1rem;
  box-sizing: border-box;
}

.modal-content h3 {
  margin-bottom: 15px;
  text-align: center; /* Center align title */
  font-size: 1.5rem;
}

/* Modal Actions Buttons */
.modal-actions {
  display: flex;
  justify-content: space-between;
  gap: 15px;
}

.save-button,
.cancel-button {
  width: 45%; /* Equal button size */
  padding: 10px;
  border-radius: 6px;
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;
  border: none;
  transition: transform 0.2s ease, background-color 0.3s ease;
}

.save-button {
  background-color: #28a745; /* Green for save */
  color: #fff;
}

.save-button:hover {
  background-color: #218838;
  transform: scale(1.05); /* Slight zoom */
}

.cancel-button {
  background-color: #dc3545; /* Red for cancel */
  color: #fff;
}

.cancel-button:hover {
  background-color: #c82333;
  transform: scale(1.05);
}

/* Add responsiveness for smaller screens */
@media (max-width: 768px) {
  .modal-content {
    width: 95%; /* Use almost full width */
    max-height: 80vh; /* Reduce height for smaller screens */
  }

  .modal-content form input,
  .modal-content form select {
    font-size: 0.9rem; /* Smaller text for smaller screens */
  }
}


.time-picker-inline {
  display: flex;
  align-items: center;
  gap: 8px; /* Space between time input and AM/PM toggle */
}

.time-picker-inline input[type="time"] {
  flex: 2; /* Adjust width of the time input */
}

.time-picker-inline select {
  flex: 1; /* Adjust width of the AM/PM toggle */
  padding: 6px;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: #fff;
  font-size: 1rem;
}

.time-picker-inline select:focus {
  outline: none;
  border-color: #3666b3; /* Match your theme */
  box-shadow: 0 0 4px #3666b3;
}



  .notification-popup {
    position: fixed;
    bottom: 40px;
    right: 40px;
    background-color: #500000;
    color: #fff;
    padding: 10px 20px;
    border-radius: 5px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    z-index: 2000;
    animation: fadeIn 0.5s ease, fadeOut 0.5s ease 2.5s;
    border: 2px solid #ffffff; /* White border for better contrast */
  }

  /* Team Modal Overlay */
.team-modal-overlay {
  position: fixed;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.6); /* Darker background for better focus */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1200;
}

/* Team Modal Content */
.team-modal-content {
  background-color: #333; /* Maroon background */
  color: #ffffff; /* White text for contrast */
  padding: 30px;
  border-radius: 12px; /* Smooth rounded corners */
  width: 400px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3); /* Subtle shadow */
  text-align: left; /* Center the text */
  animation: fadeIn 0.3s ease-in-out;
}

/* Team Modal Header */
.team-modal-content h3 {
  font-size: 1.8rem;
  margin-bottom: 20px;
  color: white; /* Gold header for attention */
  text-decoration: underline;
}

/* Team Modal Paragraph */
.team-modal-content p {
  font-size: 1rem;
  margin-bottom: 15px;
  line-height: 1.5;
}

.team-modal-content input,
.team-modal-content select {
  color: black; /* Black text for input fields */
  background-color: white; /* Ensure inputs have a white background */
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  width: 100%;
  box-sizing: border-box;
  margin-top: 5px; /* Space between label and input */
}

.team-modal-content input:focus,
.team-modal-content select:focus {
  outline: none;
  border: 1px solid #ffd700; /* Highlighted border on focus */
  box-shadow: 0 0 5px rgba(255, 215, 0, 0.75);
}

/* Team Modal Save Button */
.team-modal-actions .save-button {
  background-color: #28a745; /* Green for Save */
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 1rem;
  font-weight: bold;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.team-modal-actions .save-button:hover {
  background-color: #218838;
  transform: scale(1.05); /* Slight zoom on hover */
}

/* Team Modal Cancel Button */
.team-modal-actions .cancel-button {
  background-color: #dc3545; /* Red for Cancel */
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 1rem;
  font-weight: bold;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.team-modal-actions .cancel-button:hover {
  background-color: #c82333;
  transform: scale(1.05); /* Slight zoom on hover */
}

.white-label {
  color: white; /* White text for labels */
  display: block;
  margin-bottom: 10px; /* Add spacing between labels and inputs */
}

.form-group {
  display: flex;
  flex-direction: column; /* Stack label and input vertically */
  margin-bottom: 20px; /* Space between fields */
}

/* Keyframes for Fade In */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

  
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  @keyframes fadeOut {
    from {
      opacity: 1;
      transform: translateY(0);
    }
    to {
      opacity: 0;
      transform: translateY(20px);
    }
  }
  
  .dashboard-sidebar-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 15px;
    margin-bottom: 15px;
    border-bottom: 1px solid #ccc;
    font-size: 24px;
    font-weight: bold;
  }
  
  .dashboard-profile-pic {
    width: 70px;
    height: 70px;
    object-fit: cover;
    border: 2px solid #ffffff; /* White border for better contrast */
  }
  
  
  
  .rbc-event {
    transition: all 0.2s ease-in-out; /* Ensure smooth hover animations */
  }
  
  .cancel-paste-button {
    position: fixed; /* Keeps it in place regardless of scrolling */
    bottom: 12px; /* Distance from the bottom of the screen */
    left: 20px; /* Distance from the left side */
    background-color: #dc3545; /* Red color */
    color: white;
    border: none;
    border-radius: 8px;
    padding: 10px 20px;
    cursor: pointer;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Floating shadow effect */
    z-index: 1000; /* Ensure it appears above other elements */
    transition: transform 0.2s, box-shadow 0.2s;
}

.cancel-paste-button:hover {
    transform: translateY(-3px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
}

.cancel-paste-button:active {
    transform: translateY(2px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}
