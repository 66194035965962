.popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2000; /* Higher than the sidebar overlay */
  }
  
  .popup-content {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    text-align: center;
    animation: fadeIn 0.3s ease-in-out;
    z-index: 2001; /* Ensure it's above the overlay */
  }
  
  .popup-content h3 {
    margin-bottom: 2px;
    font-size: 1.5em;
    color: #ff4500;
  }
  
  .popup-content p {
    font-size: 1em;
    color: #333;
    margin-bottom: 10px;
  }

  /* Close Button */
.popup-close-button {
  background-color: #dc3545; /* Red background */
  color: #ffffff; /* White text */
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
  font-weight: bold;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.popup-close-button:hover {
  background-color: #a52a2a; /* Darker red on hover */
  transform: scale(1.05); /* Slight zoom effect on hover */
}
  
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  