.scheduler-container {
    display: flex;
    flex-direction: column;
    max-height: 100vh !important;
    overflow: hidden;
  }
  
  .scheduler-main {
    display: flex;
    flex: 1;
    overflow: hidden;
  }
  
  .scheduler-left {
    width: 66.66%;
    display: flex;
    flex-direction: column;
  }
  
  .scheduler-filters {
    display: flex;
    justify-content: space-around;
    color: black;
    font-size: 0.9rem;
    border-radius: 8px;
    gap: 10px;
    margin-bottom: 20px;
  }
  
  .scheduler-filters label {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    background-color: white; /* White background for filter labels */
    border: 1px solid #ddd; /* Add border around each label */
    border-radius: 5px;
    padding: 6px 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Add subtle shadow */
  }
  
  .scheduler-filters label select {
    width: 100%;
    padding: 2px;
    margin-top: 0px;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: #fff;
    font-size: 0.9rem;
    color: #333;
  }
  
  .scheduler-filters label select:focus {
    outline: none;
    border-color: #3666b3; /* Match the theme color for focus */
    box-shadow: 0 0 4px #3666b3;
  }
  
  .scheduler-calendar {
    flex: 1;
    padding: 20px;
    box-sizing: border-box;
  }
  
  .scheduler-sidebar {
    width: 33.33%;
    padding: 20px;
    background-color: #500000;
    color: #fff;
    overflow-y: auto;
    max-height: 100vh; /* Prevent overflow */
    box-sizing: border-box;

  }
  
  .scheduler-sidebar h3 {
    margin-bottom: 20px;
    text-align: center;
    font-size: 1.5rem;
    font-weight: bold;
    color: white;
  }
  
  .event-list {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .event-item {
    margin-bottom: 8px; /* Reduce space between cards */
    padding: 10px; /* Make cards thinner by reducing padding */
    background-color: #fff;
    color: #000;
    border-radius: 6px; /* Slightly sharper corners */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Softer shadow */
    display: flex;
    flex-direction: column;
    min-height: 80px; /* Set a smaller minimum height */
    max-height: 120px; /* Avoid cards being too tall */
    overflow-y: auto; /* Enable internal scrolling if needed */
  }

  .event-card {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 9px;
    background-color: #fff;
    color: #000;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    margin-bottom: 10px;
    position: relative;
}


.event-info {
  flex: 1;
}
  
  .event-item h4 {
    margin: 0 0 5px;
    font-size: 1rem; /* Reduce font size */
    color: #500000;
  }
  
  .event-item p {
    margin: 5px 0;
    font-size: 0.8rem;
    line-height: 1.4;
  }
  
  .pagination-controls {
    display: flex;
    justify-content: space-between;
    margin-top: 15px;
  }
  
  .pagination-button {
    padding: 6px 16px;
    background-color: #ffd700;
    color: #500000;
    font-weight: bold;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .pagination-button:hover {
    background-color: #e6c200;
  }

  .pagination-button:active {
    background-color: #e6c200;
    transform: translateY(5%) scale(.98);
  }
  
  /* Send Game Invite Button */
.send-invite-button {
    padding: 5px 10px; /* Smaller button size */
    font-size: 0.8rem; /* Match the smaller card text */
    background-color: #3666b3;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-top: 5px;
    align-self: flex-end; /* Align button to the right */
    transition: all 0.2s ease;
}

/* Button Hover Effect */
.send-invite-button:hover {
  background-color: #2a4f8a;
}

.send-invite-button:active {
  background-color: #2a4f8a;
  transform: translateY(5%) scale(.98);
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  color: #000;
}

.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  width: 90%; /* Take up 90% of the screen width */
  max-width: 600px; /* Limit maximum width */
  max-height: 90vh; /* Prevent overflow of the screen */
  overflow-y: auto; /* Scroll inside modal if content exceeds height */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  animation: fadeIn 0.3s ease-in-out;
}

.modal-content input {
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 1rem;
  box-sizing: border-box;
}

.modal-content button {
  padding: 10px;
  margin-top: 10px;
  cursor: pointer;
}

.send-button,
.cancel-button {
  width: 45%; /* Equal button size */
  padding: 10px;
  border-radius: 6px;
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;
  border: none;
  transition: transform 0.2s ease, background-color 0.3s ease;
}

.send-button {
  background-color: #28a745; /* Green for save */
  color: #fff;
}

.send-button:hover {
  background-color: #218838;
  transform: scale(1.05); /* Slight zoom */
}

.cancel-button {
  background-color: #dc3545; /* Red for cancel */
  color: #fff;
  margin-left: 50px; /* Add space between buttons */

}

.cancel-button:hover {
  background-color: #c82333;
  transform: scale(1.05);
}

/* Event Colors */
.event-home {
  border-left: 8px solid #1E90FF; /* Blue Border for Home */
  background-color: #fff;
  color: #000;
}

.event-away {
  border-left: 8px solid #FF4500; /* Red Border for Away */
  background-color: #fff;
  color: #000;
}

.event-home-away {
  border-left: 8px solid #28A745; /* Green Border for Home & Away */
  background-color: #fff;
  color: #000;
}

/* Calendar Event Styles */
.rbc-event-home {
  background-color: #1E90FF !important;
  border: 1px solid #0059b3;
}

.rbc-event-away {
  background-color: #FF4500 !important;
  border: 1px solid #cc3700;
}

.rbc-event-home-away {
  background-color: #28A745 !important;
  border: 1px solid #19692c;
}
