/* Header.css */

/* Header Container */
.header-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #333;
    color: white;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1000;
  }

  .hamburger-container {
    cursor: pointer;
    display: inline-block;
    position: relative;
    z-index: 1200; /* Ensure it's above sidebar */
    width: 35px; 
    height: 30px; 
  }
  
  .hamburger {
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    z-index: 1300;
    transition: transform 0.3s ease-in-out;
  }
  
  .line {
    width: 100%;
    height: 4px;
    background-color: white;
    transition: all 0.3s ease-in-out;
    border-radius: 2px;
  }
  
  .hamburger.open .line1 {
    transform: rotate(45deg) translate(5px, 5px);
    z-index: 1300;
  }
  
  .hamburger.open .line2 {
    opacity: 0; /* Hide the middle line */
  }
  
  .hamburger.open .line3 {
    transform: rotate(-45deg) translate(5px, -5px);
    z-index: 1300;
  }
  
  /* Logo Link */
  .logo {
    font-size: 1.5rem;
    font-weight: bold;
    color: #fff;
    text-decoration: none;
    margin-left: 5px;
    text-decoration: none;
  }
  
  /* Login Section */
  .login-section {
    display: flex;
    align-items: center;
    margin-left: auto;
  }
  
  .login-button, .logout-button {
    background-color: #500000;
    color: white;
    padding: 10px 18px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 18px;
    transition: background-color 0.2s;
  }
  
  .login-button:hover, .logout-button:hover {
    background-color: #750000;
  }
  
  .profile-pic {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    margin-right: 10px;
  }

  .header-container .user-info {
    display: flex;
    align-items: center;
    gap: 10px;
}

.header-container .profile-pic {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 2px solid #ddd;
}

.header-container .user-name {
    font-size: 1rem;
    font-weight: 500;
}

.header-container .logout-button {
    padding: 5px 10px;
    background-color: #f44336;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 0.9rem;
}

.header-container .logout-button:hover {
    background-color: #d32f2f;
}

.rinksync-logo {
    width: 50px;
    height: 50px;
    margin-right: 5px;
    margin-left: 15px;
    border-radius: 17%;
}

/* Header.css */
.header-invite-button {
  background-color: #3666b3;
  color: white;
  padding: 8px 16px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.2s ease;
  font-weight: bold;
  /* Put the button in the center of the header*/
  margin: 0 auto;
  display: block;
  justify-self: right;
  margin-bottom: 6px;
  font-size: 1rem;
  text-decoration: none;
  color: white;
  text-align: right;
}

.header-invite-button:hover {
  background-color: #2a4f8a;
}

.header-invite-button:active {
  background-color: #2a4f8a;
  transform: translateY(2px);
}